.header-md:after {
  background-image: none;
}

.ion-grid {
  margin-right: 1em;
  margin-left: 1em;
  height: 100%; 
}

.ion-title {
  font-weight: 600;
  text-align: end;
  margin: 2em .5em 0 0;
}

.ion-item {
  margin-top: 1em;
  
  .ion-label {
      font-weight: 600;
      font-size: 18px;
      color: #223E5C;
  }
 
}

.input-help {
  font-size: 9pt;
  text-align: right;
  color: rgba(33, 164, 114, 0.8);
}

.agree-terms {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 12px;
  margin: 2em 0;
  
  a {
      color: rgba(33, 164, 114, 0.8);
      text-decoration: none;
      font-weight: 600;
  }

  span {
      color: rgba(0, 0, 0, 0.6);
  }
  

  .ion-checkbox {
      margin-right: 1em;
  }
}

.add-card-content {
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 1.1rem !important;
}

.toolbar-container {
  background-color: white !important;
}

.bg-transparent {
  background-color: transparent;
  --background	: transparent;
}

.bg-white {
  --background	: white;
  background-color: white;
}