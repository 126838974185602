.player-wrapper {
  background-color: #F9FAFB;
  min-height: 20vh;
  position: relative;
  padding-top: 40vh;
}

.player-wrapper-sm {
  max-height: 200px;
  position: relative;
  padding-top: 20vh;
}
 
.react-player {
  position: absolute;
  top: 0;
  left: 0;
}