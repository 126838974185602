.header-md:after {
  background-image: none;
}

.ion-grid {
  margin-right: 1em;
  margin-left: 1em;
  height: 100%; 
}

.ion-title {
  font-weight: 600;
  text-align: end;
  margin: 2em .5em 0 0;
}

.ion-item {
  margin-top: 1em;
  
  .ion-label {
      font-weight: 600;
      font-size: 18px;
      color: #223E5C;
  }
 
}

.input-help {
  font-size: 9pt;
  text-align: right;
  color: rgba(33, 164, 114, 0.8);
}

.agree-terms {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 12px;
  margin: 2em 0;
  
  a {
      color: rgba(33, 164, 114, 0.8);
      text-decoration: none;
      font-weight: 600;
  }

  span {
      color: rgba(0, 0, 0, 0.6);
  }
  

  .ion-checkbox {
      margin-right: 1em;
  }
}

.ion-grid-background-image {
height: 100%;
display: flex;
flex-direction: column;
justify-content: space-around;
  background-image: url("../../../assets/back.png");
  background-repeat: repeat;
}

.greeting-logo {
  max-height: 20vh;
  max-width: 70%;
  margin: auto;
  margin-bottom: 2rem;
}

.ion-img {
  max-height: 20vh;
  max-width: 70%;
  margin: auto;
  margin-bottom: 2rem;
}

@media (min-width: 0px) {
  .greeting-logo {
      max-height: 20vh;
      max-width: 90%;
      margin: auto;
      margin-bottom: 1rem;
  }
}

@media (min-width: 640px) {
  .greeting-logo {
      max-height: 20vh;
      max-width: 75%;
      margin: auto;
      margin-bottom: 2rem;
  }
}

@media (min-width: 820px) {
  .greeting-logo {
      max-height: 20vh;
      max-width: 50%;
      margin: auto;
      margin-bottom: 2rem;
  }
}