

ion-col h5 {
	margin: 2em 0.5em 0;
	font-weight: 600;
	font-size: 18px;
	color: #223e5c;
}

.ion-hover-card {
  width: 98%;
  cursor : pointer;
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.ion-hover-card::after {
  content: "";
  border-radius: 5px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.ion-hover-card:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  z-index: 10001;
}

/* Fade in the pseudo-element with the bigger shadow */
.ion-hover-card:hover::after {
  opacity: 1;
}

.icon-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 1em;
    margin: auto;

    ion-icon {
        font-size: 24pt;
    }

    p {
        font-size: 16px;
        color: #223e5c;
        font-weight: 600;
        margin: 0 0 .5em 0;  
    }
}

.round-back {
    width: 4em;
    height: 4em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.red {
    background: #F7685B;
}

.green {
    background: #21A472;
}

.blue {
    background: #5784BE;
}

.yellow {
    background: #E38E3F;
}

.light-green {
    background: #6ED5B0;
}

.black {
    background: #223E5C;
}

ion-card {
	margin: 1em 1.5em;
  }
  
  ion-avatar {
	width: 40pt;
	height: 40pt;
	margin-right: 0.8em;
  }
  .card-title {
	display: flex;
	justify-content: flex-start;
	text-align: left;
	line-height: 2em;
  }
  
  ion-card-header {
	display: flex;
	justify-content: space-between;
  }
  
  ion-card-title {
	font-size: 16px;
	color: #223e5c;
  }
  
  ion-card-subtitle {
	color: #a6b0bf;
	font-size: 12px;
	font-weight: 400;
  }
  ion-card-content {
	font-size: 12px;
	font-weight: 400;
	color: #a6b0bf;
  }
  
  .ion-badge {
	background: rgba(110, 213, 176, 0.6);
	font-weight: 400;
  }