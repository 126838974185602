.centered {
  height: '100%';
  justify-content: 'center';
  align-items: 'center';
  display: 'flex';
}

.centered-full {
  display: flex !important;
  width: 100% !important;
  height: 100% !important;
  align-items: center !important;
  justify-content: center !important;
}

.flat-card {
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) !important;
}