.ion-content {
    --ion-background-color: #f9fafb;
}

.ion-grid-background-image {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
    background-image: url("../../assets/back.png");
    background-repeat: repeat;
}

.ion-img {
	width: 10rem;
	height: 10rem;
	margin: auto;
}

h5 {
	margin: 2rem;
	font-weight: 600;
	line-height: 1.5rem;
	color: #223e5c;
}
